import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchCurrentOrder:
      return { ...state, orderObj: payload.orderObj };
    case ActionTypes.DriverFetched:
      return { ...state, driver: payload.driver };
    case ActionTypes.LoadOrderHistory:
      return { ...state, orderHistory: payload.orderHistory };
    case ActionTypes.FetchDriverRunOrder:
      return { ...state, driverRunOrder: payload.driverRunOrder };
    case ActionTypes.FetchAllShifts:
      return { ...state, allShifts: payload.allShifts };
    default:
      return state;
  }
};
